import React from 'react';
import { Helmet } from 'react-helmet';
import { ApolloProvider, client } from './Apollo';
import GlobalStyle from './Global.style';
import AppRouter from './Router';

function App() {
    return (<ApolloProvider client={client}>
        <Helmet>
            <title>מרויחים</title>
            <meta charset='UTF-8' />
        </Helmet>
        <GlobalStyle />
        <AppRouter />
    </ApolloProvider>);
}

export default App;
