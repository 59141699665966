import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import introspectionQueryResultData from './fragmentTypes.json';
import packageJson from '../../../package.json';

const env = process.env.REACT_APP_ENV;

let uri;
switch (env) {
    case 'production':
        uri = 'https://marvihim-api.appspot.com/graphql';
        break;

    case 'staging':
        uri = 'https://marvihim-staging-api.appspot.com/graphql';
        break;

    default:
        uri = 'http://localhost:5420/graphql';
        break;
}

const httpLink = new createUploadLink({ uri });
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        }
    }
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const cache = new InMemoryCache({
    fragmentMatcher
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    name: `admin-${env}`,
    version: packageJson.version,
});

export { ApolloProvider, client };