import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

const env = process.env.REACT_APP_ENV;

i18n
    .use(XHR)
    .use(initReactI18next)
    .init({
        ns: [
            'generic', 'home', 'taxes', 'faq', 'insurance', 'bots', 'taxRefund',
            'personalZone', 'questionnaire',
        ],
        defaultNS: 'generic',
        lng: 'he',
        fallbackLng: 'he',
        debug: env === 'development',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        }
    });


export default i18n;