import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isLogged, isLoggedChanged, email, LogoutAdminUser } from './Auth';
import {
    Nav, LogoContainer, Logo, LogoText, LinksContainer, NavLink, UserContainer,
    ConnectedUser, Logout, Deploy,
} from './Navigator.style';

const {
    REACT_APP_GITLAB_TOKEN: token,
    REACT_APP_GITLAB_REF: ref,
} = process.env;

const RenderNavLink = ({ to, pathname, label }) => (
    <NavLink
        to={to}
        active={(to === pathname).toString()}
    >{label}</NavLink>
);

const Navigator = () => {
    const [logged, setLogged] = useState(isLogged);
    const [userEmail, setUserEmail] = useState(email);
    const { pathname } = useLocation();
    const { push } = useHistory();

    useEffect(() => {
        isLoggedChanged(((_isLogged, _email) => {
            setLogged(_isLogged);
            setUserEmail(_email);

            if (!_isLogged) {
                push('/login');
            }
        }));
    }, [push]);

    return <Nav>
        <LogoContainer>
            <Logo />
            <LogoText>אדמין</LogoText>
        </LogoContainer>
        <LinksContainer>
            {!!logged && <Fragment>
                <RenderNavLink to='/' label='בית' pathname={pathname} />
                <RenderNavLink to='/taxes' label='החזרי מס' pathname={pathname} />
                <RenderNavLink to='/insurance' label='ביטוח' pathname={pathname} />
                <RenderNavLink to='/faq' label='שאלות נפוצות' pathname={pathname} />
                <RenderNavLink to='/cta-button' label='CTA Button'pathname={pathname} />
                <RenderNavLink to='/bots' label='בוטים' pathname={pathname} />
                <RenderNavLink to='/questionnaires' label='שאלונים' pathname={pathname} />
                <RenderNavLink to='/personalZone' label='איזור אישי' pathname={pathname} />
            </Fragment>}
        </LinksContainer>
        {!!logged && <UserContainer>
            <ConnectedUser>
                מחובר עם חשבון
                    <br />
                {userEmail}
            </ConnectedUser>
            <Logout onClick={() => LogoutAdminUser()}>התנתק</Logout>
            <Deploy onClick={() => {
                const url = 'https://gitlab.com/api/v4/projects/13670913/trigger/pipeline';

                let formData = new FormData();
                formData.append('token', token);
                formData.append('ref', ref);

                fetch(url, {
                    method: 'POST',
                    body: formData
                });
            }}>העברה לייצור</Deploy>
        </UserContainer>}
    </Nav>;
};

export default Navigator;