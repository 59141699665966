let _token = localStorage.getItem('token');
let _name = JSON.parse(localStorage.getItem('name')) || { first: '', last: '' };
let _email = localStorage.getItem('email');
let _callbacks = [];

export let isLogged = !!_token;
export let name = _name;
export let token = _token;
export let email = _email;

export const LoginAdminUser = (name, token, email) => {
    _name = name;
    _token = token;
    _email = email;

    localStorage.setItem('token', token);
    localStorage.setItem('name', JSON.stringify(name));
    localStorage.setItem('email', email);

    isLogged = true;

    _callbacks.forEach(cb => cb(isLogged, email));
};

export const LogoutAdminUser = () => {
    _name = undefined;
    _token = undefined;
    _email = undefined;

    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');

    isLogged = false;

    _callbacks.forEach(cb => cb(isLogged));
};

export const isLoggedChanged = (callback) => {
    _callbacks.push(callback);
};