import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../icons/Logo.svg';

export const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1024px;
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 27px 0 18px 82px;
`;

export const Logo = styled(LogoSVG)`
    width: 128px;
    height: 33px;
`;

export const LogoText = styled.span`
    color: #ff4438;
    font-size: 16px;
    font-weight: 700;
`;

export const LinksContainer = styled.div`
    margin: 34px 0 0;
`;

export const NavLink = styled(Link)`
    color: ${({ active }) => active === 'true' ? '#ff4438' : '#003a70'};
    font-size: 16px;
    margin: 0 10px;
    text-decoration: none;

    :hover {
        color: #ff4438;
    }
`;

export const UserContainer = styled.div`
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const ConnectedUser = styled.div`
    color: #40709e;
    text-align: center;
    font-size: 16px;
    height: 42px;
`; 

export const Logout = styled.div`
    color: #003a70;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
`; 

export const Deploy = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 191px;
    background-color: #ff4438;
    border-radius: 6px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
`; 

