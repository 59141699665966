import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Navigator from './Navigator';
import { RouterContainer, Content } from './Router.style';

const Home = lazy(() => import('../Home'));
const Taxes = lazy(() => import('../Taxes'));
const Faq = lazy(() => import('../Faq'));
const CtaButton = lazy(() => import('../CtaButton'));
const Insurance = lazy(() => import('../Insurance'));
const Bots = lazy(() => import('../Bots'));
const PersonalZone = lazy(() => import('../PersonalZone'));
const Client = lazy(() => import('../Client'));
const Questionnaires = lazy(() => import('../Questionnaires'));
const CreateQuestionnair = lazy(() => import('../Questionnaires/Create'));
const Questionnaire = lazy(() => import('../Questionnaires/Questionnaire'));
const Login = lazy(() => import('../Login'));
const Admins = lazy(() => import('../Admins'));

const AppRouter = () => (
    <Router>
        <RouterContainer>
            <Navigator />
            <Content>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route path='/login' exact component={Login} />

                        <PrivateRoute path='/' exact component={Home} />
                        <PrivateRoute path='/admins' exact component={Admins} />
                        <PrivateRoute path='/taxes' exact component={Taxes} />
                        <PrivateRoute path='/faq' exact component={Faq} />
                        <PrivateRoute path='/cta-button' exact component={CtaButton} />
                        <PrivateRoute path='/insurance' exact component={Insurance} />
                        <PrivateRoute path='/bots' exact component={Bots} />
                        <PrivateRoute path='/personalZone' exact component={PersonalZone} />
                        <PrivateRoute path='/client/:id' exact component={Client} />
                        <PrivateRoute path='/questionnaires' exact component={Questionnaires} />
                        <PrivateRoute path='/questionnaires/create' exact component={CreateQuestionnair} />
                        <PrivateRoute path='/questionnaires/:id' exact component={Questionnaire} />

                        <Redirect to='/' />
                    </Switch>
                </Suspense>
            </Content>
        </RouterContainer>
    </Router>
);

export default AppRouter;
